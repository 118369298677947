import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../../components/Layout';

const ServicesIndex = () => (
  <Layout
    title="Listing of many of the services offered at Axiom Geomatics. Such as RPR and Development Permit Surveys"
    description="Description of services offered  for example Development Permit, subdivision, condominium and RPR Surveys."
    keywords="residential survey, construction survey, construction service, legal survey, alberta land surveyor, land surveyor, new home construction, professional land surveyor"
  >
    <main>
      <h1>Services Offered</h1>
      <p>
        Axiom Geomatics provides numerous professional survey services. Select category of service
        are you looking for:
      </p>

      <h2>Residential Surveys</h2>
      <p>
        Residential surveys include all surveys that a homeowner commonly requires. This includes
        Real Property Reports (and updates) and Fence / Property Line Surveys.
      </p>

      <p>
        <Link to="/services/residential-surveys">See all Residential Surveys</Link>
      </p>

      <hr />

      <h2>Construction Services</h2>
      <p>
        Construction services include all surveys and work required by architects, landscapers,
        developers, engineers and construction crews. These include Development Permit surveys,
        streetscapes, house stakeouts, utility construction services and more.
      </p>

      <p>
        <Link to="/services/construction-services">See all Construction Services</Link>
      </p>

      <hr />

      <h2>Legal Surveys</h2>
      <p>
        Legal surveys include all work related with ownership and transference of property rights.
        Such surveys include Real Property Reports, subdivision surveys and lease area surveys.
      </p>

      <p>
        <Link to="/services/legal-surveys">See all Legal Surveys</Link>
      </p>
    </main>
  </Layout>
);

export default ServicesIndex;
